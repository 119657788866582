<template>
  <div class="device-page">
    <transition name="fade" mode="out-in" appear>
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Devices',
}
</script>

<style lang="scss" scoped>

</style>
